import styled from "@emotion/styled";
import UtilizeSpacer from "@/styles/theme-styles";
import { TableCell, TableContainer } from "@mui/material";
import defaultTheme from "@/styles/themes/default";
import Link from "next/link";
import Widget from "../Widget";

export const Wrapper = styled.div<{ $noActionItems?: boolean }>`
  height: 300px;
  display: block;
  width: 30%;

  ${(props) =>
    props.$noActionItems &&
    `
    height: auto;
  `}
`;

export const Container = styled(TableContainer)`
  height: 100%;
  max-height: 300px;
  display: block;
  border-radius: 16px;
  border: 1px solid ${defaultTheme.renewTheme.colors.grey300};
  ${UtilizeSpacer({
    options: [
      {
        size: "2xs",
        direction: [0],
      },
      {
        size: "xs",
        direction: [2],
      },
    ],
    type: "padding",
  })}
  box-shadow: none;
` as typeof TableContainer;

export const CellLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
  color: ${defaultTheme.renewTheme.colors.night400};
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 2px;
  display: block;
`;

export const Cell = styled(TableCell)<{ $urgent?: boolean; $header?: boolean }>`
  ${UtilizeSpacer({
    options: [
      {
        size: "0",
        direction: [2, 4],
      },
      {
        size: "3xs",
        direction: [1, 3],
      },
    ],
    type: "padding",
  })}
  *:not(a) {
    color: ${defaultTheme.renewTheme.colors.grey600};
    ${(props) =>
      props.$urgent &&
      `
    color: ${defaultTheme.renewTheme.colors.error600};
  `}
  }
  ${(props) =>
    props.$header &&
    `
    color: ${defaultTheme.renewTheme.colors.black};
    font-weight: 600;
  `}
`;

export const WidgetContainer = styled(Widget)`
  width: 30%;
`;
