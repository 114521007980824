import styled from "@emotion/styled";
import PageHeader from "@/components/PageHeader";
import UtilizeSpacer from "@/styles/theme-styles";
import { Link } from "@mui/material";
import defaultTheme from "@/styles/themes/default";
import Sent from "@/assets/images/svgs/Sent";
import Complete from "@/assets/images/svgs/Complete";
import { Text } from "@/components/Typography";
import Pill from "@/components/Pill";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";

export const Container = styled.div``;
export const RmsContainer = styled.div`
  background: #fff;
  padding-top: 42px;
`;

export const CellLink = styled(Link)`
  text-decoration: underline;
  color: ${defaultTheme.renewTheme.colors.night400};
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 4px;
  display: block;
`;

export const Header = styled(PageHeader)`
  ${UtilizeSpacer({
    options: [
      {
        size: "sm",
        direction: [3],
      },
      {
        size: "3xs",
        direction: [0],
      },
    ],
    type: "padding",
  })}
`;

export const FilterItem = styled(Pill)``;

export const InfoIcon = styled(InfoOutlinedIcon)`
  height: 10px;
  width: 10px;
`;

export const AttachEmailIcon = styled(AttachEmailOutlinedIcon)`
  height: 10px;
  width: 10px;
`;

export const AttachEmailIconStandaloneWrapper = styled.div`
  padding: 4px 8px;
  border-radius: 1rem;
  background-color: ${defaultTheme.renewTheme.colors.arctic400};
`;

export const SentIcon = styled(Sent)`
  height: 100% !important;
  width: 100% !important;
  min-height: 26px !important;
  min-width: 26px !important;
`;

export const CompleteIcon = styled(Complete)`
  height: 100% !important;
  width: 100% !important;
  min-height: 29px !important;
  min-width: 29px !important;
`;

export const Highlight = styled(Text)<{ $type: "messages" | "actions" }>`
  border-radius: 4px;
  margin-left: 8px;
  padding: 8px;
  min-width: 86px;
  color: ${defaultTheme.renewTheme.colors.black};
  display: flex;
  justify-content: center;

  ${({ $type }) =>
    $type === "messages"
      ? `
    background-color: ${defaultTheme.renewTheme.colors.arctic400};
  `
      : `
    background-color: ${defaultTheme.renewTheme.colors.warning400};
  `}
`;

export const WidgetWrapper = styled.div`
  display: flex;
  gap: 8px;
  ${UtilizeSpacer({
    options: [
      {
        size: "0",
        direction: [0],
      },
      {
        size: "sm",
        direction: [3],
      },
      {
        size: "xs",
        direction: [1, 2],
      },
    ],
    type: "margin",
  })}
`;
