import useCurrentUser from "@/graphql/hooks/user/useUser";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import useRenewalOfferBatches from "@/graphql/hooks/renewalOfferBatches/useRenewalOfferBatches";
import { RenewalOfferContext } from "@/context/renewal-offer/context";
import { SelectChangeEvent } from "@mui/material";
import useSegmentTrackEvent from "@/hooks/useSegmentTrackEvent";
import { buildDropDownFilterPayload } from "@/utils/buildDropDownFilterPayload";
import { BatchesByCommunity, Value } from "./types";

export default function useConnect() {
  const { setRenewalOfferState } = useContext(RenewalOfferContext);
  const router = useRouter();
  const { query, push, pathname } = router;
  const { data: user } = useCurrentUser();
  const { data: batches } = useRenewalOfferBatches();
  const { callTrack } = useSegmentTrackEvent();
  type Batch = typeof batches;
  type IndividualBatch = NonNullable<Batch>[number];
  const [batchesByCommunity, setBatchesByCommunity] = useState<
    { [key: string]: { id: string; batches: IndividualBatch[] } } | undefined
  >();

  const [filters, setFilters] = useState<{
    community?: string;
    communityId?: string;
    leaseEndDate?: string;
    batchId?: string;
    month?: string;
    year?: string;
  }>({
    community: { ...query }.community as string,
    leaseEndDate: { ...query }.leaseEndDate as string,
    batchId: { ...query }.batchId as string,
    communityId: { ...query }.communityId as string,
    month: { ...query }.month as string,
    year: { ...query }.year as string,
  });

  useEffect(() => {
    setRenewalOfferState({
      batchId: { ...query }.batchId as string,
      communityId: { ...query }.communityId as string,
      month: { ...query }.month as string,
      year: { ...query }.year as string,
    });
  }, [query, setRenewalOfferState]);

  const getDefaultCommunity = (communityBatches: BatchesByCommunity) =>
    communityBatches
      ? {
          name: Object.keys(communityBatches)?.[0],
          id: communityBatches?.[Object.keys(communityBatches)?.[0]]?.id,
        }
      : undefined;

  const getDefaultLeaseEndDate = (
    communityBatches: BatchesByCommunity,
    community?: string
  ) =>
    community
      ? {
          leaseEndDate:
            communityBatches?.[community]?.batches?.[0]?.leaseEnd.label,
          month: communityBatches?.[community]?.batches?.[0]?.month,
          year: communityBatches?.[community]?.batches?.[0]?.year,
        }
      : undefined;

  const getBatchIdForCommunity = (
    communityBatches: BatchesByCommunity,
    leaseEndDate: string,
    community?: string
  ) => {
    if (!community || !communityBatches?.[community]) return undefined;

    if (leaseEndDate) {
      return communityBatches[community].batches.find(
        (batch) => batch.leaseEnd.label === leaseEndDate
      )?.id;
    }

    return communityBatches[community]?.batches[0]?.id;
  };

  useEffect(() => {
    setFilters((prevFilters) => {
      const community =
        prevFilters.community || getDefaultCommunity(batchesByCommunity)?.name;
      const communityId =
        prevFilters.communityId || getDefaultCommunity(batchesByCommunity)?.id;
      const leaseEndDate =
        prevFilters.leaseEndDate ||
        getDefaultLeaseEndDate(batchesByCommunity, community)?.leaseEndDate;
      const month =
        prevFilters.month ||
        getDefaultLeaseEndDate(batchesByCommunity, community)?.month;
      const year =
        prevFilters.year ||
        getDefaultLeaseEndDate(batchesByCommunity, community)?.year;
      const batchId = getBatchIdForCommunity(
        batchesByCommunity,
        leaseEndDate || "",
        community
      );

      return {
        community,
        communityId,
        leaseEndDate,
        batchId,
        month,
        year,
      };
    });
  }, [user, batchesByCommunity]);

  useEffect(() => {
    if (batches) {
      setBatchesByCommunity(
        batches.reduce(
          (
            accumulator: {
              [key: string]: { id: string; batches: IndividualBatch[] };
            },
            batch
          ) => {
            const state = accumulator;
            if (!state[batch.community]) {
              state[batch.community] = {
                id: "",
                batches: [],
              };
            }
            state[batch.community].id = batch.communityId;
            state[batch.community].batches.push(batch);
            return state;
          },
          {}
        )
      );
    }
  }, [batches]);

  useEffect(() => {
    // Initialize queryParams with the current query parameters from the URL
    const queryParams: { [key: string]: string | string[] | undefined } = {
      ...query,
    };

    const communityValue = filters.community;
    if (typeof communityValue === "string" && communityValue.trim() !== "") {
      queryParams.community = communityValue;
      queryParams.communityId = filters.communityId;
      // If the filter value is empty or not applicable, remove the key from queryParams
    } else {
      delete queryParams.community;
      delete queryParams.communityId;
      delete queryParams.leaseEndDate;
    }

    const leaseEndDateValue = filters.leaseEndDate;
    if (
      typeof leaseEndDateValue === "string" &&
      leaseEndDateValue.trim() !== ""
    ) {
      queryParams.leaseEndDate = leaseEndDateValue;
      queryParams.month = filters.month;
      queryParams.year = filters.year;
      // If the filter value is empty or not applicable, remove the key from queryParams
    } else {
      delete queryParams.leaseEndDate;
    }

    const batchIdValue = filters.batchId;
    if (typeof batchIdValue === "string" && batchIdValue.trim() !== "") {
      queryParams.batchId = batchIdValue;
      // If the filter value is empty or not applicable, remove the key from queryParams
    } else {
      delete queryParams.leaseEndDate;
      delete queryParams.batchId;
    }

    // Update the URL with the new query parameters without triggering a full page reload
    void push(
      {
        pathname,
        query: queryParams,
      },
      undefined,
      { shallow: true }
    );
  }, [filters, pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterChangeTrackCall = (
    value: Value,
    filterChangeUpdate: {
      community: string | undefined;
      leaseEndDate: string | undefined;
      batchId: string | undefined;
      month: string | undefined;
      year: string | undefined;
      communityId: string | undefined;
    }
  ) => {
    const payloadConfig = {
      community: {
        filterName: "community",
        filterOptions: batchesByCommunity && Object.keys(batchesByCommunity),
        selectedFilters: [filterChangeUpdate.community],
      },
      leaseEnd: {
        filterName: "leaseEndDate",
        filterOptions:
          filterChangeUpdate.community &&
          batchesByCommunity?.[filterChangeUpdate.community].batches.map(
            (batch) => batch.leaseEnd.label
          ),
        selectedFilters: [filterChangeUpdate.leaseEndDate],
      },
    };

    const selectedFilter = value.community ? "community" : "leaseEnd";

    const payload = buildDropDownFilterPayload({
      // when a user selects from the leaseEndDate dropdown, the selected value does not contain community
      selected: value.community || value.leaseEndDate,
      active: true,
      filterName: payloadConfig[selectedFilter].filterName,
      filterOptions: payloadConfig[selectedFilter].filterOptions,
      selectedFilters: payloadConfig[selectedFilter].selectedFilters,
      filterGroupName: "RMS Community Batch Selection",
      filterGroupState: {
        leaseEndDate: [filterChangeUpdate.leaseEndDate],
        community: [filterChangeUpdate.community],
      },
    });

    callTrack({ eventName: "filter.click", payload });
  };

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const value: Value = JSON.parse(e.target.value as string) as {
      id: string;
      community?: string;
      communityId?: string;
      leaseEndDate?: string;
      month?: string;
      year?: string;
    };
    setRenewalOfferState({
      batchId: value.id,
      communityId: value.communityId,
      month: value.month,
      year: value.year,
    });

    const community = value.community || filters.community;
    const filterChangeUpdate = {
      ...filters,
      community,
      communityId: value.communityId,
      leaseEndDate: value.leaseEndDate,
      month: value.month,
      year: value.year,
      batchId:
        value.id ||
        (community && batchesByCommunity?.[community]?.batches?.[0].id),
    };

    setFilters(filterChangeUpdate);
    filterChangeTrackCall(value, filterChangeUpdate);
  };
  return {
    setFilters,
    filters,
    batchesByCommunity,
    handleChange,
  };
}
