/* eslint-disable @typescript-eslint/no-floating-promises */
import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { withoutNulls } from "@/utils/withoutNulls";
import GET_LEASES from "@/graphql/queries/leases/getLeases";
import normalizeLeases from "@/model/Leases";

export default function useLeases({
  month,
  year,
  communityId,
  excludeRecordsWithActiveOffers,
}: {
  month?: string;
  year?: number;
  communityId?: string;
  excludeRecordsWithActiveOffers: boolean;
}) {
  const {
    data: raw,
    loading,
    error,
    refetch,
  } = useQuery(GET_LEASES, {
    variables: {
      month,
      year,
      communityId,
      excludeRecordsWithActiveOffers,
    },
    skip: !month || !year || !communityId || month === "" || communityId === "",
  });

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.leases);
    return data && normalizeLeases(data);
  }, [raw]);

  return { data: normalized, isLoading: loading, error, refetch };
}
