import { createContext, useState } from "react";

type StateOptions = {
  batchId?: string;
  communityId?: string;
  month?: string;
  year?: string;
};
interface RenewalOffer {
  renewalOfferState: StateOptions;
  setRenewalOfferState: React.Dispatch<React.SetStateAction<StateOptions>>;
}
export const RenewalOfferContext = createContext<RenewalOffer>(
  {} as RenewalOffer
);

const RenewalOfferProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [renewalOfferState, setRenewalOfferState] = useState<StateOptions>({});
  return (
    <RenewalOfferContext.Provider
      value={{ renewalOfferState, setRenewalOfferState }}
    >
      {children}
    </RenewalOfferContext.Provider>
  );
};

export default RenewalOfferProvider;
