import { RenewalOfferBatch } from "@/graphql/generated";
import type { WithoutNulls } from "@/utils/withoutNulls";

export default function normalizeRenewalOfferBatches(
  input: WithoutNulls<RenewalOfferBatch[]>
) {
  return input.map((batch) => {
    // Convert the long month name to a short month name
    const shortMonth = batch.month.slice(0, 3);

    // Create a sortable date
    const date = new Date(`${shortMonth} 1 ${batch.year}`);
    return {
      id: batch.id,
      leaseEnd: {
        label: `${batch.month} ${batch.year}`,
        date: date.toISOString().split("T")[0], // Format the date as 'yyyy-mm-dd'
      },
      month: batch.month,
      year: batch.year,
      community: batch.community.name,
      communityId: batch.community.id,
      renewalOfferPdfsAvailable: batch.renewalOfferPdfsAvailable,
    };
  });
}
export type RenewalOfferBatches = ReturnType<
  typeof normalizeRenewalOfferBatches
>;
