import { LeaseFragment } from "@/graphql/generated";
import type { WithoutNulls } from "@/utils/withoutNulls";

export default function normalizeLeases(input: WithoutNulls<LeaseFragment[]>) {
  return input.map((lease) => ({
    id: lease.id,
    toDate: lease?.toDate,
    community: lease?.unit?.community,
    unit: {
      id: lease?.unit?.id,
      name: lease?.unit?.marketingName,
      building: lease?.unit?.buildingName,
      communityName: lease?.unit?.community.name,
    },
  }));
}

export type Leases = ReturnType<typeof normalizeLeases>;
