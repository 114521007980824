import { LeasesQuery } from "@/graphql/generated";
import { TypedDocumentNode, gql } from "@apollo/client";

const GET_LEASES: TypedDocumentNode<LeasesQuery> = gql`
  query Leases(
    $month: Month!
    $year: Int!
    $communityId: ID!
    $excludeRecordsWithActiveOffers: Boolean!
  ) {
    leases(
      month: $month
      year: $year
      communityId: $communityId
      excludeRecordsWithActiveOffers: $excludeRecordsWithActiveOffers
    ) {
      id
      toDate
      unit {
        id
        marketingName
        buildingName
        community {
          id
          name
        }
      }
    }
  }
`;

export default GET_LEASES;
