import DropdownFilters from "./partials/DropdownFilters";
import * as Styled from "./styles";
import useConnect from "./connect";
import StatusTables from "./partials/StatusTables";
import ActionItems from "./partials/ActionItems";
import Leases from "./partials/Leases";

const RenewalOffers = () => {
  const {
    selectedWorkflowStatusFilters,
    handleWorkflowStatusFilterClick,
    isWorkflowStatusFilterActive,
    workflowStatusAndIconMap,
  } = useConnect();
  return (
    <Styled.Container>
      <Styled.Header title="Renewal Offers Overview" />
      <DropdownFilters />
      <Styled.WidgetWrapper>
        <ActionItems />
        <Leases />
      </Styled.WidgetWrapper>
      <Styled.RmsContainer>
        <Styled.Header size="small" title="Renewal Offers">
          {workflowStatusAndIconMap.map(({ workflowStatusFilter }) => (
            <Styled.FilterItem
              key={workflowStatusFilter}
              label={workflowStatusFilter}
              small
              variant={
                isWorkflowStatusFilterActive(workflowStatusFilter)
                  ? "filled"
                  : "outlined"
              }
              onClick={() =>
                handleWorkflowStatusFilterClick(workflowStatusFilter)
              }
            />
          ))}
        </Styled.Header>
        <StatusTables
          selectedWorkflowStatusFilters={selectedWorkflowStatusFilters}
        />
      </Styled.RmsContainer>
    </Styled.Container>
  );
};

export default RenewalOffers;
